<template>
  <div>

    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formCliente" lazy-validation>

      <v-container grid-list-md fluid>

        <v-widget title="Informações Cadastrais" tem-campo-obrigatorio>
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field label="CNPJ/CPF *" v-model="participante.cnpj_cpf" @change="consultarCnpj(participante.cnpj_cpf)" v-mask="['###.###.###-##', '##.###.###/####-##']" :rules="validarCnpjCpf" :loading="carregando" :readonly="estaEditando || carregando" required data-cy="cnpj_cpf" @keypress.enter="$refs.inscricao_estadual.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Inscrição Estadual *" ref="inscricao_estadual" v-model="participante.inscricao" :rules="validarInscricao" required data-cy="inscricao" @keypress.enter="$refs.rg.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="RG" ref="rg" v-model="participante.rg" data-cy="rg" v-mask="'###############'" @keypress.enter="$refs.razao_nome.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field label="Razão Social / Nome *" ref="razao_nome" v-model="participante.razao_nome" :rules="validadarRazaoNome" required data-cy="razao_nome" @keypress.enter="$refs.suframa.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="SUFRAMA" ref="suframa" v-model="participante.suframa" @keypress.enter="$refs.nascimento.focus()"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-autocomplete ref="tipo" label="Tipo" v-model="participante.tipo" :items="constantes.TIPOS_CLIENTE_FORNECEDOR" :disabled="true"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data label="Nascimento" ref="nascimento" v-model="participante.nascimento" :rules="validarData" @keypress.enter="$refs.limite_credito.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field label="Limite de Crédito" ref="limite_credito" v-model="participante.limite_credito" :rules="validarValor" data-cy="limite_credito" @keypress.enter="$refs.cep.focus()"></v-text-field>
              </v-col>
              <v-col v-show="participante.data_cadastro" cols="12" sm="3">
                <campo-data label="Data de Cadastro" v-model="participante.data_cadastro" disabled></campo-data>
              </v-col>
            </v-row>
          </template>
        </v-widget>

        <v-widget title="Informações de Contato" class="mt-4" tem-campo-obrigatorio>
          <template v-slot:widget-content>
              <v-row dense>
                <v-col cols="12" sm="4">
                  <campo-cep v-mask="'#####-###'" label="CEP" ref="cep" buscarcep @resultado="setEndereco" v-model="participante.cep" append-icon="map" @keypress.enter="$refs.logradouro.focus()"></campo-cep>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field label="Logradouro *" ref="logradouro" v-model="participante.logradouro" :rules="validarLogradouro" required data-cy="logradouro" @keypress.enter="$refs.numero.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4"> 
                  <v-text-field label="Número *" ref="numero" v-model="participante.numero" :rules="validarNumero" required data-cy="numero" @keypress.enter="$refs.complemento.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="Complemento" ref="complemento" v-model="participante.complemento" data-cy="complemento" @keypress.enter="$refs.bairro.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4"> 
                  <v-text-field label="Bairro *" ref="bairro" v-model="participante.bairro" :rules="validarBairro" required data-cy="bairro" @keypress.enter="$refs.estado.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete label="Estado" ref="estado" v-model="participante.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(participante.estado)"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete label="Cidade *" ref="cidade" v-model="participante.codigo_cidade" @change="setCidade(participante.codigo_cidade)" :items="cidades" :rules="validarCidade" :disabled="cidades.length == 0 || $store.state.participante.carregando_cidade" :loading="$store.state.participante.carregando_cidade" @keypress.enter="$refs.pais.focus()"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="País *" ref="pais" v-model="participante.pais" :rules="validarPais" @keypress.enter="$refs.telefone.focus()" :readonly="true" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="Telefone" ref="telefone" v-model="participante.telefone" v-mask="'(##)####-####'" data-cy="telefone" @keypress.enter="$refs.celular.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="Celular" ref="celular" v-model="participante.celular" v-mask="'(##)####-####'" data-cy="celular" @keypress.enter="$refs.email.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="E-Mail *" ref="email" v-model="participante.email" :rules="validarEmail" data-cy="email" @keypress.enter="$refs.observacoes.focus()"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-textarea class="pt-0 mt-0" label="Observações" ref="observacoes" v-model="participante.observacoes" data-cy="observacoes"></v-textarea>
                </v-col>
              </v-row>
          </template>          
        </v-widget>
        
        <v-widget title="Produtos do Contrato" class="mt-4">
          <template v-slot:widget-content>
            <v-row class="align-rigth text-right">
              <v-spacer></v-spacer>
              <v-col class="pl-0 pr-0 pt-0 pb-0">
                <v-btn color="primary" @click="abrirDialogoProdutoContrato">Adicionar Produto</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table v-if="participante.produtoscontrato.length > 0" :headers="headersProdutosContrato" hide-default-footer :server-items-length="participante.produtoscontrato.length" :items="participante.produtoscontrato">
                  <template v-slot:item="{item}">
                    <tr>
                      <td class="font-weight-bold">{{ item.produto }}</td>
                      <td class="font-weight-bold text-right">{{ item.serie }}</td>
                      <td class="text-center">
                        <v-menu top right transition="slide-x-transition" @click.native.stop="">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                          </template>
                          <v-list dense>
                            <v-list-item :key="item._id + 10" @click="editarProdutoContrato(item)" data-cy="editar">
                              <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                              <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item :key="item._id + 15" @click="excluirProdutoContrato(item._id)" data-cy="remover">
                              <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                              <v-list-item-title>Remover</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </template>

          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">Cancelar</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar" data-cy="salvar">Salvar</v-btn>
          </template>
        </v-widget>

      </v-container>
    </v-form>  
    
    <v-dialog v-model="exibirAdicionarEditarProdutoContrato" max-width="680px" persistent>
      <v-form ref="formProdutoContrato" lazy-validation>
        <v-card>
          <v-toolbar flat dark dense color="primary">
            <v-toolbar-title>Produto</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4">
            <v-row dense>
              <v-col cols="8" xs="12" sm="8">
                <v-text-field label="Produto/Descrição" ref="produtocontrato" v-model="produtoContrato.produto" :rules="[rules.obrigatorio]" @keypress.native.enter="$refs.produtocontrato_serie.focus()"></v-text-field>
              </v-col>
              <v-col cols="4" xs="12" sm="4">
                <v-text-field label="Série" ref="produtocontrato_serie" v-model="produtoContrato.serie" :rules="[rules.obrigatorio]" @keypress.native.enter="salvarProdutoContrato"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
          <v-btn @click="exibirAdicionarEditarProdutoContrato = false" :disabled="carregando" text>Cancelar</v-btn>
          <v-btn color="primary" :disabled="carregando" :loading="carregando" @click="salvarProdutoContrato">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>


<script>
  import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
  import * as regras from '@/util/validacaoFormulario';
  import constantes from '@/util/constantes';
  import validador from '@/util/validador';
  import { mask } from 'vue-the-mask';

  export default {
    name: "FormularioParticipante",
    directives: {
      mask
    },

    data: () => ({
      valid: true,
      loading: false,
      validarCnpjCpf: [v => (v && v.length === 14 ? validador.cpf(v): (v.length === 18 ? validador.cnpj(v): false)) || "CNPJ/CPF Inválido"],
      validarInscricao: [v => !!v || "Inscrição Obrigatória"],
      validadarRazaoNome: [v => !!v || "Razão/Nome Obrigatório", v => (v && v.length < 200) || 'Tamanho Máximo são 200 Caracteres'],
      validarCep: [v => !!v || "CEP Obrigatório"],
      validarLogradouro: [v => !!v || "Logradouro Obrigatório"],
      validarNumero: [v => !!v || "Número Obrigatório"],
      validarBairro: [v => !!v || "Bairro Obrigatório"],
      validarCidade: [v => !!v || "Cidade Obrigatória"],
      validarPais: [v => !!v || "País Obrigatório"],
      validarEmail: [v => (v && validador.email(v)) || "E-mail Inválido"],
      validarData: [v => !v || (v && validador.dataNascimento(v) || 'Data Inválida')],
      validarValor: [v => !v || (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || "Valor Inválido"],
      rules: {...regras},
      constantes: constantes,
      headersProdutosContrato: [
        { text: 'Produto', value: 'produto', sortable: false, width: '70%' },
        { text: 'Série', value: 'serie', align: 'right', width: '20%' },
        { text: 'Ações', value: 'acoes', align: 'center', sortable: false, width: '10%' }
      ],
      exibirAdicionarEditarProdutoContrato: false,
      produtoContrato: {
        _id: 1,
        produto: '',
        serie: ''
      },
      contadorIDProdutosContrato: 1
    }),

    beforeRouteLeave(to, from, next) {
      this.$store.commit('participante/setCidades', []);
      next();
    },

    async created(){
      try {
        this.loading = true;
        let estaEditando = this.$route.path.indexOf('editar') > -1 ? true : false;
    
        if(estaEditando && (!this.participante._id || this.participante._id === '')){
          this.$store.commit('setMensagemErro', 'Selecione um cliente/fornecedor para editar');
          this.$router.push({ name: 'participantes' });
        } else if (estaEditando) {
          if (this.participante.estado)
            await this.filtrarCidadesEstado(this.participante.estado);
        }
    
        if (!estaEditando) {
          this.$store.commit('participante/estadoInicial');
        }
        
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    methods: {
      ...mapActions('participante', {
        filtrarCidadesEstado: 'filtrarCidadesEstado'
      }),

      ...mapMutations('participante', {
        setCidade: 'setCidade'
      }),

      async salvar() {
        if (this.$refs.formCliente.validate()) {
          try {
            let inscricao = `${this.participante.inscricao}-${this.participante.estado}`;
            let isInscricaoValida = validador.inscricaoEstadual(inscricao);
            if(isInscricaoValida){
              await this.$store.dispatch('participante/salvar');
              this.$router.push({ name: 'participantes' });
            }
            else{
              this.$store.commit('setMensagemErro', `Inscrição ${inscricao} inválida`);
            }
          } catch (error) {
            console.log(error);
          }
        }
      },

      cancelar() {
        this.$router.push({ name: 'participantes' });
      },

      setEndereco(endereco) {
        this.$store.commit('participante/setEndereco', endereco);
        this.filtrarCidadesEstado(this.participante.estado)
        this.$refs.cidade.focus();
      },
      
      abrirDialogoProdutoContrato() {
        this.exibirAdicionarEditarProdutoContrato = true;
        setTimeout(() => {
          this.$refs.produtocontrato.focus();
        },100);
      },

      editarProdutoContrato(produto) {
        this.produtoContrato._id = produto._id
        this.produtoContrato.produto = produto.produto
        this.produtoContrato.serie = produto.serie
        this.abrirDialogoProdutoContrato();
      },

      excluirProdutoContrato(id) {
        this.$store.commit('participante/EXCLUIR_PRODUTO_CONTRATO', id);
      },
      
      salvarProdutoContrato() {
        if (this.$refs.formProdutoContrato.validate()) {
          this.exibirAdicionarEditarProdutoContrato = false;
          this.$store.commit('participante/ADICIONAR_PRODUTO_CONTRATO', this.produtoContrato);
          this.contadorIDProdutosContrato = this.contadorIDProdutosContrato+1
          this.produtoContrato = {
            _id: this.contadorIDProdutosContrato,
            produto: '',
            serie: ''
          }
        }
      },

      consultarCnpj(cnpj){
        if(cnpj.length === 18 && !this.carregando){
          let cnpjConsulta = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('/', '').replace('-', '');
          this.$store.dispatch('participante/consultarCadastro', cnpjConsulta);
        }
      }
    },

    computed: {
      ...mapState('participante', {
        participante: 'participanteAtual',
        carregando: 'carregando',
      }),
      
      ...mapGetters('participante', {
        estaEditando: 'estaEditando',
        textoBtnConfirmar: 'textoBtnConfirmar',
        cidades: 'listaCidades',
      }),
    }
  };
</script>

<style scoped>

</style>